
import { lazy } from 'react';

import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Navbar from "./components/Navbar";
import Topbar from "./components/Topbar";
import Footer from "./components/Footer";
import Landing from "./components/Landing";
import ImpactPage from "./components/ImpactPage";
import ContactUs from "./components/ContactUs";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Returns from "./components/Returns";
import Grassroots from './components/Grassroots.jsx';
import Smartschools from './components/Smartschools.jsx';
const AboutUs = lazy(() => import('./components/AboutUs.jsx'));
const VernacularPage = lazy(() => import('./components/VernacularPage.jsx'));
const HybridPage = lazy(() => import('./components/HybridPage.jsx'));
const AssessmentPage = lazy(() => import('./components/AssessmentPage.jsx'));
const Careers = lazy(() => import('./components/Careers.jsx'));



function App() {
  return (
    <BrowserRouter>
    <div>
      <Topbar />
      <Navbar />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/vernacular' element={<VernacularPage />} />
        <Route path='/hybrid' element={<HybridPage />} />
        <Route path='/assessment' element={<AssessmentPage />} />
        <Route path='/impact' element={<ImpactPage />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/returns' element={<Returns />} />
        <Route path='/grassroots' element={<Grassroots />} />
        <Route path='/smart' element={<Smartschools />} />
      </Routes>
      <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;
