import React, {useState} from 'react';
import {pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import Faq  from "./Faq";
import {faqlist1} from "./FaqList1";
import {faqlist2} from "./FaqList2";
import { FaWhatsapp, FaPhone } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import image1 from '../assets/Image-1.jpg';
import image2 from '../assets/Image-2.jpg';
import image3 from '../assets/Image-3.jpg';
import image4 from '../assets/Image-4.jpg';
import image5 from '../assets/Image-5.jpg';

const data = [
  {
    id: 1,
    img: 'https://images.schooglink.com/image/website/news1.png',
  },
  {
    id: 2,
    img: 'https://images.schooglink.com/image/website/news3.png',
  },
  {
    id: 3,
    img: 'https://images.schooglink.com/image/website/news4.png',
  },
  {
    id: 4,
    img: 'https://images.schooglink.com/image/website/news5.png',
  },
  {
    id: 5,
    img: 'https://images.schooglink.com/image/website/news6.png',
  },
  {
    id: 6,
    img: 'https://images.schooglink.com/image/website/news8.png',
  },
  {
    id: 7,
    img: 'https://images.schooglink.com/image/website/news9.png',
  },
  ]

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Grassroots = () => {
  
    const [cards1] = useState(faqlist1);
    const [cards2] = useState(faqlist2);
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav);
    };

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    const slideRight = () => {
      var slider = document.getElementById('slider');
      slider.scrollLeft += 280;
    }

    const slideLeft = () => {
      var slider = document.getElementById('slider');
      slider.scrollLeft -= 280;
    }

    return (
        <div className='px-4 py-16'>
            <div className='flex flex-col justify-center items-center'>
                <h1 className='text-3xl font-extrabold'> स्कूग्लिंक ग्रासरूट्स सेंटर्स </h1>
            </div>
            <div className='relative mt-12'>
                {/* Image with text overlay for smaller and larger screens */}
                <div className='relative w-full h-[350px] sm:h-[400px] lg:h-[600px]'>
                    <img 
                        src="https://images.schooglink.com/image/website/grassroots.png" 
                        alt='Grassroots' 
                        className='w-full h-full object-cover' 
                    />
                    <div className="absolute inset-0 bg-teal-400 opacity-40 "></div>

                    {/* Text overlay for both screen sizes */}
                    <div className='absolute inset-0 bg-black/60 flex flex-col justify-center items-center lg:items-start lg:h-[600px]'>
                        <h1 className='text-xl text-white sm:text-2xl md:text-3xl lg:text-4xl px-4 py-4 font-extrabold lg:ml-48'>
                                सक्षम भारत की नींव
                        </h1>
                        <p className='text-sm sm:text-base md:text-lg lg:text-xl px-4 text-white lg:max-w-[25%] lg:ml-48'>
                            2018 से स्कूग्लिंक ग्रासरूट्स स्मार्ट क्लासरूम ने भारत के कोने - कोने 
                            में शिक्षा जगत में बदलाव की शुरुआत देखी है जहाँ छात्रों के साथ साथ 
                            युवाओं को भी अपने करियर में आगे बढ़ने का सुनहरा मौका मिला है।
                        </p>
                    </div>
                </div>

                {/* Buttons go below the image for smaller screens and on the image for larger screens */}
                <div className='flex flex-col lg:absolute lg:inset-0 lg:flex-row gap-4 px-4 py-6 lg:py-20 lg:justify-start lg:items-center lg:ml-48 lg:mt-[400px] sm:static'>
                <a href='contactus'>
                <button className='bg-white text-black px-8 py-2 rounded-[10px] font-bold'>
                    स्मार्ट क्लास डेमो बुक करें
                </button>
                </a>
                <a href='contactus'>
                <button className='bg-green-500 text-white px-8 py-2 rounded-[10px] font-bold'>
                    ग्रासरूट्स शिक्षक बनें
                </button>
                </a>
                </div>
            </div>


            <div className="px-4 py-8">
                <section className="max-w-4xl mx-auto border border-gray-400 rounded-lg">
                <div className="flex flex-col lg:flex-row gap-8">
      
                    {/* Scrollable List Section */}
                    <div className="flex-1 flex flex-col justify-between h-96">
                        <div className="flex-1 overflow-y-auto p-4">
                            <section className="space-y-4">
                                {cards1.map((card, index) => (
                                <div key={index} className="relative pl-6">
                                    {/* Dot styling */}
                                    <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-3 h-3 bg-gradient-to-r from-yellow-400 via-yellow-300 to-yellow-200 rounded-full"></span>
                                    <div className="bg-white shadow-lg rounded-lg p-4 border border-gray-200 hover:shadow-xl transition-shadow duration-300">
                                        <Faq {...card} className="text-sm sm:text-base md:text-lg font-bold text-gray-900" />
                                    </div>
                                </div>
                                ))}
                            </section>
                        </div>
                    </div>

                    {/* YouTube Video Section */}
                    <div className="flex-1 flex justify-center items-center h-96 p-4">
                        <div className="w-full max-w-full relative" style={{ paddingBottom: '56.25%' }}>
                            <iframe
                            src="https://www.youtube.com/embed/a2mlPTYfRAQ"
                            title="Schooglink Grassroots - Concept है सरल !"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                            className="absolute top-0 left-0 w-full h-full border-l border-gray-400"
                            ></iframe>
                        </div>
                    </div> 
                </div>
                </section>
            </div>


            <div className='flex flex-col gap-8 justify-center items-center'>
                <p className='text-justify'>
                    ऑनलाइन - ऑफलाइन हाइब्रिड मॉडल क्लासरूम के साथ भारत के कोने कोने तक उच्चतम शिक्षा पहुँचाता हुआ, 
                    स्कूग्लिंक ग्रासरूट्स सेंटर 4 राज्यों में निरंतर विकास कर रहा है और भारत के अन्य क्षेत्रों तक पहुँचने के पथ पर अग्रसर है ।
                </p>
            </div>

            <div className='flex flex-col gap-8 justify-center items-center px-4 py-8'>
                <h1 className='justify-center text-2xl'> 
                    स्कूग्लिंक ग्रासरूट्स की पहल और प्रभाव
                </h1>
            </div>
                  
            <div className='flex flex-row justify-between items-center border border-gray-400 rounded-lg max-w-4xl mx-auto min-h-[50vh] lg:h-64'>
                {/* Scrollable List Section */}
                <div className='w-1/2 overflow-y-auto h-full p-4'>
                    <section className="space-y-4">
                    {cards2.map((card, index) => (
                    <div key={index} className="relative  pl-6 bg-white shadow-lg rounded-lg border border-gray-200 hover:shadow-xl transition-shadow duration-300">
                        {/* Dot styling */}
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-3 h-3 bg-gradient-to-r from-yellow-400 via-yellow-300 to-yellow-200 rounded-full"></span>
                        <div className="pl-6 p-4">
                            <Faq {...card} className="text-sm sm:text-base md:text-lg font-bold text-gray-900" />
                        </div>
                    </div>
                    ))}
                    </section>
                </div>
  
                {/* Image Section */}
                <img src="https://images.schooglink.com/image/website/leaflet.png" alt='Pamphlet' className='w-1/2 h-full object-contain border-l border-gray-400' />
            </div>
            {/*
            <div className='flex flex-col gap-8 justify-center items-center px-4 py-8'>
                <h1 className='justify-center text-3xl font-bold'> 
                    हमारे सेंटर
                </h1>
            </div>
            <div>
                <div className='flex flex-wrap justify-center items-center gap-4 bg-gray-100 min-h-[400px] lg:min-h-[600px]'>
                    <img src="https://images.schooglink.com/image/website/india.png" alt='India' className='h-72 w-72 lg:h-96 lg:w-96' />
                    <div className='flex flex-col justify-center items-center gap-10'>
                        <div className='flex flex-row gap-10'>
                            <div className='bg-white/50 rounded-2xl p-2'>
                                <img src="https://images.schooglink.com/image/website/up.png" alt='Uttar Pradesh' className='h-32 w-32 lg:h-36 lg:w-52 rounded-lg' />
                            </div>
                            <div className='bg-white/50 rounded-2xl p-2'>
                                <img src="https://images.schooglink.com/image/website/mp.png" alt='Madhya Pradesh' className='h-32 w-32 lg:h-36 lg:w-52 rounded-lg' />
                            </div>
                        </div>
                        <div className='bg-white/50 rounded-2xl p-2'>
                            <img src="https://images.schooglink.com/image/website/jh.png" alt='Jharkhand' className='h-32 w-32 lg:h-36 lg:w-52 rounded-lg' />
                        </div>
                    </div>

                </div>
            </div>
            */}
            <div className="mt-12 px-4 py-4">
                {/* Heading */}
                <h2 className="text-2xl font-bold text-center mb-12">स्कूग्लिंक ग्रासरूट्स का रंग बिरंगा वातावरण  </h2>

                {/* Image Row */}
                <div className="flex justify-center gap-16 flex-wrap mb-16">
                    {/* Five Images */}
                    <img src={image1} alt="Image 1" className="w-40 h-40 object-cover rounded-xl border border-gray-300" />
                    <img src={image2} alt="Image 2" className="w-40 h-40 object-cover rounded-xl border border-gray-300" />
                    <img src={image3} alt="Image 3" className="w-40 h-40 object-cover rounded-xl border border-gray-300" />
                    <img src={image4} alt="Image 4" className="w-40 h-40 object-cover rounded-xl border border-gray-300" />
                    <img src={image5} alt="Image 5" className="w-40 h-40 object-cover rounded-xl border border-gray-300" />
                </div>
            </div>
            <div className='flex flex-col gap-8 justify-center items-center px-4 py-8 '>
                <h1 className='text-2xl font-bold text-center'> 
                    स्कूग्लिंक ग्रासरूट्स सेंटर्स एवं टीचर्स की अधिक जानकारी के लिए हमें संपर्क करें
                </h1>

                <div className='flex flex-wrap gap-12 lg:gap-24 justify-center items-start '>
                    <div className='flex flex-col items-center text-center bg-gray-200 px-10 py-5 rounded-xl'>
                        <h1 className='text-xl font-bold mb-2'>ग्रासरूट्स शिक्षक बनें</h1>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-2'>
                            <FaPhone className='text-blue-600' /> 
                            <FaWhatsapp className='text-green-600' />
                            <span>+91 62039 68604</span>
                        </div>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-4'>
                            <MdOutlineEmail className='text-red-600' /> 
                            <span>contact@schooglink.com</span>
                        </div>
                        <button className='border-blue-950 text-blue-950 py-2 px-4 rounded-md hover:bg-blue-950 hover:text-white transition'>आवेदन पत्र भरें </button>
                    </div>

                    <div className='flex flex-col items-center text-center bg-gray-200 px-10 py-5 rounded-xl'>
                        <h1 className='text-xl font-bold mb-2'>ग्रासरूट्स ट्यूशन सेंटर जॉइन करें</h1>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-4'>
                            <FaPhone className='text-blue-600' /> 
                            <span>+91 80695 10555</span>
                        </div>
                        <button className='border-green-900 text-green-900 py-2 px-4 rounded-md hover:bg-green-900 hover:text-white transition'>संपर्क करें</button>
                    </div>

                    <div className='flex flex-col items-center text-center bg-gray-200 px-10 py-5 rounded-xl'>
                        <h1 className='text-xl font-bold mb-2'>अपने शहर में ग्रासरूट्स सेंटर खोलें</h1>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-2'>
                            <FaPhone className='text-blue-600' /> 
                            <FaWhatsapp className='text-green-600' />
                            <span>+91 62039 68601</span>
                        </div>
                        <div className='flex flex-row justify-center items-center gap-2 text-gray-700 mb-4'>
                            <MdOutlineEmail className='text-red-600' /> 
                            <span>contact@schooglink.com</span>
                        </div>
                        <button className='border-orange-700 text-orange-700 py-2 px-4 rounded-md hover:bg-orange-700 hover:text-white transition'>संपर्क करें</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Grassroots
