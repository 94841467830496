import React from 'react'

const Privacy = () => {
  return (
    <div className='flex flex-col mt-24 px-12'>
      <h1 className='text-3xl items-center mt-12'> Privacy Policy </h1>
      <p lang="en-IN" align="justify"><span>The following privacy policy (“</span><span><strong>Policy</strong></span><span>”) applies to the data collected at website,</span><span>&nbsp;</span><span><a href="http://schooglink.com/">http://schooglink.com/</a> (“</span><span><strong>Website</strong></span><span>”), and the Schooglink web or mobile application (“</span><span><strong>App</strong></span><span>”) that is owned and operated by Schooglink Private Limited (“</span><span><strong>We</strong></span><span>”, “</span><span><strong>Us</strong></span><span>”, “</span><span><strong>Company</strong></span><span>” or “</span><span><strong>Our</strong></span><span>”, which expression shall mean and include its affiliates, successors and assigns). The Website and App shall hereinafter collectively be referred to as the “</span><span><strong>Platform</strong></span><span>”. We</span><span> are committed to protecting and respecting Your privacy.</span></p>
	<p lang="en-IN" align="justify"><span>By mere visiting or usage of the Website, download or usage of the App or availing of the services available on the Platform, You acknowledge that You accept the practices and policies outlined in this Policy. This Policy constitutes a legal agreement between You, as the user of the Platform, and </span><span>the Company</span><span>, as the owner of the Platform. This Policy does not apply to third-party websites that are connected </span><span><em><span>via</span></em></span><span> links to the Platform. </span></p>
	<p lang="en-IN" align="justify"><span>This Policy has been drafted in compliance with the applicable data protection rules and regulations of India.</span></p>
	<p lang="en-IN" align="justify"><strong><span>BY VOLUNTARILY PROVIDING US WITH INFORMATION, YOU ARE CONSENTING TO OUR USE OF IT IN ACCORDANCE WITH THIS POLICY.</span></strong></p>
	<ol>
	   <li>
	      <span><u><strong>Applicability</strong></u></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>This Policy describes Our policies and procedures on the collection, use, storage and disclosure of any information provided by You (“</span><span><strong><span>You/r</span></strong></span><span>”)</span><span> while using the Platform</span><span>. </span></p>
	<p lang="en-IN" align="justify"><span>Your use of the Platform will be governed by this Policy in addition to the </span><span>Terms of Use (located at </span><span><a href="https://schooglink.com/terms-of-use">https://schooglink.com/terms-of-use</a></span><span>)</span><span>&nbsp;as applicable to You</span><span>. </span></p>
	<p lang="en-IN" align="justify"><span>The Platform is primarily designed to act as a medium to help the user exchange and share information with other users of the Platform. Therefore, any information You provide to Us in any public space of the Platform such as blogs, chat groups and open group interactive messages is information You understand is and intend to make public and this Policy does not extend to such public information shared by You on the Platform. When You share information or content like photos, videos, and links on any public space of the Platform, You should think carefully about what you are making public. </span></p>
	<ol start="2">
	   <li>
	      <span><u><strong>Collection of Information</strong></u></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>We will use the personal information provided by You only in accordance with the purposes described in the Policy.</span></p>
	<p lang="en-IN" align="justify"><span><br/></span><span>During Your use of the Platform, We may collect and process such information from You, including but not limited to the below mentioned: </span></p>
	<ol type="a">
	   <li>
	      <span>Information that You provide to Us by creating an account on the Platform. This includes profile picture/image, contact information such as name, email address, mailing address, phone number, financial information, </span><span><em><span>if any</span></em></span><span>, unique identifiers such as user name, account number, password&nbsp;and preferences information such as favourites lists, user history;&nbsp;</span>
	   </li>
	   <li>
	      <span>Information that You provide when You write directly to Us (including by e-mail), or You provide to Us by writing on our blogs, or You enter on the Platform via computer or any wireless device, or that You provide to Us in any other way;</span>
	   </li>
	   <li>
	      <span>Information that You provide to Us when You register on the Platform, use any of the features of the Platform, participate in any surveys conducted by Us or carry out transactions on the Platform;</span>
	   </li>
	   <li>
	      <span>Information relating to logs is automatically reported by Your browser each time You access our Platform. When You use the Platform, Our servers automatically record certain information that Your browser sends whenever You visit the Platform or any website. These server logs may include information such as Your web request, Internet Protocol (IP) address, browser type, referring/ exit pages and URLs, number of clicks, domain names, landing pages, pages viewed, and other such information. We use this information, which does not identify users, to analyze trends, to administer the Platform, to track users’ movements around the Platform and to gather demographic information about the user base as a whole.&nbsp;We do not link this automatically-collected data to personally identifiable information. </span>
	   </li>
	   <li>
	      <span>When You use the Platform, We may employ clear web beacons which are used to track Your online usage patterns anonymously. No personally identifiable information from You is collected using these web beacons. In addition, We may also use clear web beacons in HTML-based e-mails sent to You to track which e-mails are opened/ viewed. Such collected information is used to enable more accurate reporting and making the Platform better for Our users.</span>
	   </li>
	   <li>
	      <span>We may collect information about Your general internet usage by using a cookie file which is stored on the hard drive of Your device.&nbsp;Cookies help Us improve Our Platform and deliver a better and personalized service.&nbsp;Cookies enable Us:</span>
	   </li>
	</ol>
	<ol type="i">
	   <li>
	      <span>to estimate Our users’ usage size and pattern;</span>
	   </li>
	   <li>
	      <span>to store information about Your preferences, and allow Us to customize Our Platform according to Your interests;</span>
	   </li>
	   <li>
	      <span>to speed up Your searches;</span>
	   </li>
	   <li>
	      <span>to recognize You when You return to Our Platform.</span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify">“<span><strong><span>Cookies</span></strong></span><span>” are small files that reside on Your computer's or device's hard drive and generally contain an anonymous unique identifier and are accessible only by the website that placed them there and not any other sites. You may refuse to accept Cookies by activating the setting on Your browser which allows You to refuse the setting of Cookies.&nbsp;However, if You select this setting You may be unable to access certain parts of the Platform.&nbsp;Unless You have adjusted Your browser setting so that it will refuse Cookies, Our system may issue Cookies when You log on to the Platform. The use of Cookies by Our partners, affiliates, advertisers or service providers is not covered by the Policy.&nbsp; </span></p>
	<p lang="en-IN" align="justify"><span>The Company </span><span>may use the services of certain third parties, </span><span>for the purpose of operating and administering the Platform</span><span>. Such third party service providers may collect the information sent by Your browser as part of a web page request, including Cookies and your IP address and such information will be governed by the privacy policies of the third party service providers.</span></p>
	<ol start="3">
	   <li>
	      <span><u><strong>Use of the Information</strong></u></span>
	   </li>
	</ol>
	<span>We may use the information provided by You in the following ways, </span><span><em><span>viz</span></em></span><span>:</span>
	<ol type="a">
	   <li>
	      <span>monitor, improve and administer the Platform;</span>
	   </li>
	   <li>
	      <span>conduct research and analysis;</span>
	   </li>
	   <li>
	      <a name="_GoBack"></a> <span>analyze how the Platform is used, diagnose service or technical problems, maintain security;</span>
	   </li>
	   <li>
	      <span>remember information to help You efficiently access the Platform;</span>
	   </li>
	   <li>
	      <span>monitor aggregate metrics such as total number of viewers, visitors, traffic, and demographic patterns;</span>
	   </li>
	   <li>
	      <span>to confirm Your identity in order to ensure that You are eligible to Use the Platform.</span>
	   </li>
	   <li>
	      <span>to ensure that content from the Platform is presented in the most effective contact You to ensure user satisfaction with respect to Your use of the Platform;</span>
	   </li>
	   <li>
	      <span>to provide You with information that You request from Us, where You have consented to be contacted for such purposes;</span>
	   </li>
	   <li>
	      <span>to carry out Our obligations arising from any contracts entered into between You and Us;</span>
	   </li>
	   <li>
	      <span>provide you with the information about products and services available on the Platform;</span>
	   </li>
	   <li>
	      <span>improve the services and content on the Platform;</span>
	   </li>
	   <li>
	      <span>improving your experience of navigating through the Platform and carrying out interactions and transactions on the Platform;</span>
	   </li>
	   <li>
	      <span>to notify You about changes on the Platform;</span>
	   </li>
	   <li>
	      <span>to enable Us to comply with Our legal and regulatory obligations;</span>
	   </li>
	   <li>
	      <span>We may also use Your information, and/ or permit selected third parties including service providers on the Platform, with Your prior consent, to use Your information or provide You with</span><span> such information which may be of interest to You and We and/ or they may contact You about the same.&nbsp;If You wish to be so contacted by the third parties, any information You provide to such third parties may be read, collected, and used by them. Notwithstanding the abovementioned, You may choose to stop receiving such information from the third parties by writing to Us at </span><span>support@schooglink.com.</span><span>. </span>
	   </li>
	</ol>
	<ol start="4">
	   <li>
	      <span><u><strong><span>Disclosure </span></strong></u></span><span><u><strong>of</strong></u></span><span><u><strong><span> Information</span></strong></u></span>
	   </li>
	</ol>
	<span>We will share Your personal information with third parties only in such manner as described below:&nbsp; </span>
	<ol type="a">
	   <li>
	      <span>We may share Your information with third parties with Your prior consent and in such an event, the third parties’ use of Your information will be bound, among other things, by the Policy. We may store information in locations outside the direct control of the Company (for instance, on servers or databases co-located with hosting providers).</span>
	   </li>
	   <li>
	      <span>We may disclose Your information, </span><span>with Your prior consent,</span><span> to any member of Our related or group companies including Our subsidiaries, Our ultimate holding company and its subsidiaries, as the case may be.</span>
	   </li>
	   <li>
	      <span>In the event that We sell or buy any business or assets, We may disclose Your information,</span><span> with Your prior consent,</span><span> to the prospective seller or buyer of such business or assets. User</span><span>, email, and visitor information is generally one of the transferred business assets in these types of transactions. </span>
	   </li>
	   <li>
	      <span>We may disclose Your information to third-party service-providers, with Your prior consent, solely in the course of their provision of services to Us. We will take reasonable precautions to ensure that these service-providers are obligated to maintain the confidentiality of Your information.</span>
	   </li>
	   <li>
	      <span>We may disclose Your information if We are under a duty to do so in order to comply with any legal obligation, or in order to enforce or apply Our Terms of Use </span><span>or assign such information in the course of corporate divestitures, mergers, or</span><span> to protect the rights, property, or safety of Us, Our users, or others.&nbsp;This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</span>
	   </li>
	   <li>
	      <span>We may disclose Your information to governmental and other statutory bodies who have appropriate authorisation to access the same for any specific legal purposes.</span>
	   </li>
	   <li>
	      <span>If you make any transaction on the Platform, we may disclose necessary information with the vendor and payment gateway service provider to enable You to complete Your transaction and facilitate payment.</span>
	   </li>
	</ol>
	<ol start="5">
	   <li>
	      <span><u><strong><span>Your </span></strong></u></span><span><u><strong>Choices</strong></u></span><span><u><strong><span> about Your Information</span></strong></u></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>You may decline to submit identifiable information through the Platform, in which case You may not be allowed to access certain features / parts of the Platform. If You are a registered user, You may update or correct Your account information and email preferences at any time by logging in to Your account. Alternatively, if You believe that any of Your information held by Us is inaccurate, You may write to Us at </span><span>support@schooglink.com.</span> <span>It is Your responsibility to ensure that any information You provide Us remains accurate and updated. </span></p>
	<p lang="en-IN" align="justify"><span>We will use and retain Your information for such periods as necessary to comply with Our legal obligations, resolve disputes, and enforce Our agreements.</span></p>
	<p lang="en-IN" align="justify"><span>If You wish to subscribe to Our newsletter(s), We will use Your name and email address to send the newsletter to You.&nbsp; However, </span><span>You may choose to stop receiving Our newsletters by following the instructions to unsubscribe as included in these e-mails or You can contact Us at&nbsp;</span><span>support@schooglink.com. </span></p>
	<ol start="6">
	   <li>
	      <span><u><strong><span>Links to </span></strong></u></span><span><u><strong>Third</strong></u></span><span><u><strong><span> Party Sites</span></strong></u></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>Our Platform may, from time to time, contain links to and from the websites of Our partner networks, affiliates and other third parties. </span><span>The inclusion of a link does not imply any endorsement by Us of the third party Platform, the Platform's provider, or the information on the third party Platform.</span><span>&nbsp;If You follow a link to any of these Platforms, please note that these Platforms may be governed by their own privacy policies and We disclaim all responsibility or liability with respect to these policies or the Platforms.&nbsp;Please check these policies and the terms of the Platforms before You submit any information to these Platforms.</span></p>
	<ol start="7">
	   <li>
	      <span><u><strong>Storage and </strong></u></span><span><u><strong>Security</strong></u></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>The Company </span><span>uses commercially reasonable safeguards to preserve the integrity and security of Your information </span><span>against loss, theft, unauthorised access, disclosure, reproduction, use or amendment.</span></p>
	<p lang="en-IN" align="justify"><span>All information You provide to Us is stored on Our secure servers within India.&nbsp;</span></p>
	<p lang="en-IN" align="justify"><span>As a registered user with an account and a password, You are responsible for keeping Your password confidential.&nbsp;</span></p>
	<p lang="en-IN" align="justify"><span>By submitting Your information on Our Platform, You agree to the transfer, storing and/ or processing of Your information in a manner as stated under this Policy.&nbsp;We will take such steps as We consider reasonably necessary to ensure that Your information is treated securely and in accordance with the Policy. </span></p>
	<p lang="en-IN" align="justify"><span>In using the Platform, You accept the inherent security implications of data transmission over the internet and the World Wide Web cannot always be guaranteed as completely secure. Therefore, Your use of the Platform will be at Your own risk. </span></p>
	<p lang="en-IN" align="justify"><span>We assume no liability for any disclosure of information due to errors in transmission, unauthorised third party access or other acts of third parties, or acts or omissions beyond Our reasonable control and You agree that You will not hold Us responsible for any breach of security unless such breach has been caused as a direct result of Our gross negligence or wilful default.</span></p>
	<p lang="en-IN" align="justify"><span>In the event We become aware of any breach of the security of Your information, We will promptly notify You and take appropriate action to the best of Our ability to remedy such a breach. </span></p>
	<ol start="8">
	   <li>
	      <span><u><strong>Exclusion</strong></u></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>This Policy does not apply to any information other than such information collected by the Company through the Platform. This Policy shall not apply to any unsolicited information You provide Us through this Platform or through any other means. Further this Policy shall not apply to any information posted to any public areas of the Website. All such unsolicited information or public information shall be deemed to be non-confidential and the Company shall be free to use, disclose such unsolicited or public information without any limitations.</span></p>
	<ol start="9">
	   <li>
	      <span><u><strong>Severability</strong></u></span><span>&nbsp;</span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>We have made every effort to ensure that this Policy adheres with the applicable laws. The invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or enforceability of the remainder of this Policy.</span></p>
	<ol start="10">
	   <li>
	      <span><u><strong>No </strong></u></span><span><u><strong>Waiver</strong></u></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>The rights and remedies of available under this Policy may be exercised as often as necessary and are cumulative and not exclusive of rights or remedies provided by law. It may be waived only in writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a waiver of that right or remedy, or any other right or remedy.</span></p>
	<ol start="11">
	   <li>
	      <span><u><strong>Governing</strong></u></span><span><u><strong> Law and Dispute Resolution</strong></u></span>
	   </li>
	</ol>
	<p className="western" lang="en-IN" align="justify"><span>This Policy shall be governed by and construed in accordance with the laws of the Republic of India. The courts at </span><span>Bangalore</span><span>shall have exclusive jurisdiction in relation to any disputes arising out of or in connection with this Policy. </span></p>
	<ol start="12">
	   <li>
	      <span><u><strong><span>Changes to the Policy</span></strong></u></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>We may update this Policy to reflect changes to Our practices. If We make any material changes We may notify You by e-mail (sent to the e-mail address specified in Your account) or by means of a notice on this Platform prior to the change becoming effective. We encourage You to periodically review the Policy for the latest information on Our privacy practices.</span></p>
	<ol start="13">
	   <li>
	      <span><u><strong>Contact</strong></u></span>
	   </li>
	</ol>
	<p lang="en-IN" align="justify"><span>Please address any grievances or </span><span>questions</span><span> You may have, without limitation, with respect to the collection, processing, usage, disclosure of Your information in writing to Us at </span><span>support@schooglink.com</span><span>. You can always withdraw Your consent for usage, processing of Your information by Us by writing to Us at the address mentioned above.</span></p>
  </div>
  )
}

export default Privacy