import React from 'react';
import img from '../assets/punchline.png';
import img_mobile from '../assets/punchline_mobile.png';

const Punchline = () => {
  return (
    <div className='relative lg:w-[80%] mx-auto mt-8 md:mt-16 lg:mt-32 xl:mt-32'>
      {/* For large screens */}
      <img
        src={img}
        alt=''
        className='hidden sm:block w-full h-full object-cover rounded-lg'
      />
      {/* For mobile screens */}
      <img
        src={img_mobile}
        alt=''
        className='block sm:hidden w-full h-full object-cover rounded-lg'
      />

      {/* Overlay */}
      <div className='absolute inset-0 px-4 py-4'></div>

      {/* Text on mobile image */}
      <div className='absolute top-4 left-0 right-0 px-4 sm:hidden'>
        <div className='text-white text-center  p-4 rounded-lg'>
          <p className='text-l font-semibold leading-relaxed'>
            Schooglink delivers affordable and quality education, using a tech-driven, hybrid model of learning, with localized vernacular content, currently focused on the 255 million k-12 students in rural India.
          </p>
          <h1 className='text-xl font-bold mt-8'>
            ACCESSIBLE | AFFORDABLE | ASPIRATIONAL
          </h1>
        </div>
      </div>

      {/* Text on large screens */}
      <div className='absolute inset-0 flex items-center px-4 justify-center sm:items-center sm:justify-start max-w-[70%]'>
        <div className='hidden sm:block text-white'>
          <p className='text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl lg:w-2/3 lg:ml-12 mt-4 sm:mt-0'>
            Schooglink delivers affordable and quality education, using a tech-driven, hybrid model of learning, with localized vernacular content, currently focused on the 255 million k-12 students in rural India.
          </p>
          <h1 className='text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl lg:ml-12 font-bold mt-8'>
            ACCESSIBLE | AFFORDABLE | ASPIRATIONAL
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Punchline;

