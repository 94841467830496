import React, { useState } from 'react';
import Viewer from './Viewer';

// Import images from assets folder
import graph2024 from '../assets/highlights_page/graph2024.png';
import graph2023 from '../assets/highlights_page/graph2023.png';
import graph2022 from '../assets/highlights_page/graph2022.png';
import topper1 from '../assets/topper1.png';
import topper2 from '../assets/topper2.png';
import topper3 from '../assets/topper3.png';

const testimonials = [
  {
    name: "Shivam Kumar",
    location: "Bihar State Board Rank 08 Class X",
    videoUrl: "https://www.youtube.com/embed/FsAAT5UnzJE?modestbranding=1&showinfo=0&rel=0",
  },
  {
    name: "Afreen Khatun",
    location: "Madhya Pradesh State Board District Anuppur Rank 02 Class X",
    videoUrl: "https://www.youtube.com/embed/gT6b4eXQxJ0?modestbranding=1&showinfo=0&rel=0",
  },
  {
    name: "Sushant Kumar Das",
    location: "Jharkhand State Board Block Sonahatu Rank 01 Class X",
    videoUrl: "https://www.youtube.com/embed/r06xuQWI56s?modestbranding=1&showinfo=0&rel=0",
  },
];

const toppers = [
  {
    name: "Shivam Kumar",
    location: "Bihar",
    score: "BSEB X-95.4%",
    image: topper1
  },
  {
    name: "Afreen Khatun",
    location: "Madhya Pradesh",
    score: "MPBSE X-97.75%",
    image: topper2
  },
  {
    name: "Sushant Kumar Das",
    location: "Jharkhand",
    score: "JAC X-93.80%",
    image: topper3
  }
];

const ImpactPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [
    { src: graph2024, alt: "Schooglink Grassroots Performance 2024" },
    { src: graph2023, alt: "Schooglink Grassroots Performance 2023" },
    { src: graph2022, alt: "Schooglink Grassroots Performance 2022" },
  ];

  return (
    <div className='mt-12 px-4 py-4'>
      

      {/* Toppers Section */}
      <div className="flex flex-col items-center mb-12 mt-8">
        <h2 className="text-3xl font-bold mb-8 text-center">Our Toppers</h2>
        <div className="flex flex-nowrap overflow-x-auto gap-4">
          {toppers.map((topper, index) => (
            <div key={index} className="flex flex-col items-center w-40 h-auto p-4">
              <img
                src={topper.image}
                alt={topper.name}
                className="w-32 h-32 object-cover mb-4"
              />
              <h3 className="text-base font-semibold text-center">{topper.name}</h3>
              <p className="text-sm text-gray-600 text-center">{topper.location}</p>
              <p className="text-sm text-gray-600 text-center">{topper.score}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Testimonial Section */}
      <div className="flex flex-col items-center">
        <h2 className="text-3xl font-bold mb-8 text-center">Testimonial</h2>
        <div className="flex flex-col lg:flex-row justify-center gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex flex-col items-center w-80 h-72 p-4 "
            >
              <div className="aspect-w-16 aspect-h-9 w-full ">
                <iframe
                  className="w-full h-full rounded-lg border"
                  src={testimonial.videoUrl}
                  title={testimonial.name}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
              <h3 className="mt-4 text-lg font-semibold">{testimonial.name}</h3>
              <p className="text-gray-600 text-center mb-2">{testimonial.location}</p>
            </div>
          ))}
        </div>
      </div>



      <div className="flex flex-col items-center justify-center p-4 mt-8">
        {/* Heading */}
        <h1 className="text-2xl font-bold text-center mb-6">
          Schooglink Grassroot Performance
        </h1>

        {/* Images Container for Mobile Screens */}
        <div className="md:hidden flex flex-col items-center">
          {/* Active Image */}
          <div className="w-full h-auto">
            <img
              src={images[activeIndex].src}
              alt={images[activeIndex].alt}
              className="w-full h-[260px] object-cover"
            />
          </div>
          {/* Dots for navigation */}
          <div className="flex justify-center mt-2 space-x-2">
            {images.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index === activeIndex ? 'bg-blue-500' : 'bg-gray-400'
                }`}
                onClick={() => setActiveIndex(index)}
              ></button>
            ))}
          </div>
        </div>

        {/* Images Container for Larger Screens */}
        <div className="hidden md:grid grid-cols-1 md:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div key={index} className="w-full h-auto">
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-[260px] object-cover"
              />
            </div>
          ))}
        </div>
      </div>

      {/* PDF Viewer */}
      <Viewer filename="impact_report_2023_lite.pdf" />
    </div>
  );
};

export default ImpactPage;
 