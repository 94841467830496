import React from 'react';

const Features = () => {
  return (
    <div className='w-[90%] mx-auto h-full mt-16'>
      {/* For larger screens: all items in one row */}
      <div className='grid grid-cols-1 sm:grid-cols-6 gap-4 justify-items-center'>
  <div className='flex flex-col items-center'>
    <img src="https://images.schooglink.com/image/website/presence.png" alt='' className='h-20 sm:h-20 lg:h-24 object-contain' />
    <p className='text-sm sm:text-base md:text-lg lg:text-3xl font-bold'>4</p>
    <p className='text-center text-xs sm:text-sm md:text-base whitespace-nowrap'>STATES</p>
  </div>
  <div className='flex flex-col items-center'>
    <img src="https://images.schooglink.com/image/website/smart_classroom.png" alt='' className='h-20 sm:h-20 lg:h-24 object-contain' />
    <p className='text-sm sm:text-base md:text-lg lg:text-3xl font-bold'>120</p>
    <p className='text-center text-xs sm:text-sm md:text-base whitespace-nowrap'>SMART CLASSROOMS</p>
  </div>
  <div className='flex flex-col items-center'>
    <img src="https://images.schooglink.com/image/website/students.png" alt='' className='h-20 sm:h-20 lg:h-24 object-contain' />
    <p className='text-sm sm:text-base md:text-lg lg:text-3xl font-bold'>50,000+</p>
    <p className='text-center text-xs sm:text-sm md:text-base whitespace-nowrap'>CLASSROOM STUDENTS</p>
  </div>
  <div className='flex flex-col items-center'>
    <img src="https://images.schooglink.com/image/website/app_downloads.png" alt='' className='h-20 sm:h-20 lg:h-24 object-contain' />
    <p className='text-sm sm:text-base md:text-lg lg:text-3xl font-bold'>8,00,000+</p>
    <p className='text-center text-xs sm:text-sm md:text-base whitespace-nowrap'>APP DOWNLOADS</p>
  </div>
  <div className='flex flex-col items-center'>
    <img src="https://images.schooglink.com/image/website/curriculum.png" alt='' className='h-20 sm:h-20 lg:h-24 object-contain' />
    <p className='text-sm sm:text-base md:text-lg lg:text-3xl font-bold'>23</p>
    <p className='text-center text-xs sm:text-sm md:text-base whitespace-nowrap'>CURRICULUM</p>
  </div>
  <div className='flex flex-col items-center'>
    <img src="https://images.schooglink.com/image/website/vernacular_medium.png" alt='' className='h-20 sm:h-20 lg:h-24 object-contain' />
    <p className='text-sm sm:text-base md:text-lg lg:text-3xl font-bold'>2</p>
    <p className='text-center text-xs sm:text-sm md:text-base whitespace-nowrap'>VERNACULAR MEDIUM</p>
  </div>
</div>


      {/* For smaller screens: 3 images per row with respective text below
      <div className='block sm:hidden'>
        <div className='grid grid-cols-3 gap-4 justify-items-center'>
          <img src="https://images.schooglink.com/image/website/presence.png" alt='' className='h-16 object-contain' />
          <img src="https://images.schooglink.com/image/website/smart_classroom.png" alt='' className='h-16 object-contain' />
          <img src="https://images.schooglink.com/image/website/students.png" alt='' className='h-16 object-contain' />
        </div>
        <div className='grid grid-cols-3 gap-4 justify-items-center mt-4'>
          <p className='text-sm font-bold'>4</p>
          <p className='text-sm font-bold'>120</p>
          <p className='text-sm font-bold'>50,000+</p>
        </div>
        <div className='grid grid-cols-3 gap-4 justify-items-center mt-2'>
          <p className='text-center text-xs font-bold'>STATES</p>
          <p className='text-center text-xs font-bold'>
            SMART <br /> CLASSROOMS
          </p>
          <p className='text-center text-xs font-bold'>
            CLASSROOM <br /> STUDENTS
          </p>
        </div>
        <div className='grid grid-cols-3 gap-4 justify-items-center mt-8'>
          <img src="https://images.schooglink.com/image/website/app_downloads.png" alt='' className='h-16 object-contain' />
          <img src="https://images.schooglink.com/image/website/curriculum.png" alt='' className='h-16 object-contain' />
          <img src="https://images.schooglink.com/image/website/vernacular_medium.png" alt='' className='h-16 object-contain' />
        </div>
        <div className='grid grid-cols-3 gap-4 justify-items-center mt-4'>
          <p className='text-sm font-bold'>8,00,000+</p>
          <p className='text-sm font-bold'>23</p>
          <p className='text-sm font-bold'>2</p>
        </div>
        <div className='grid grid-cols-3 gap-4 justify-items-center mt-2'>
          <p className='text-center text-xs font-bold'>
            APP <br /> DOWNLOADS
          </p>
          <p className='text-center text-xs font-bold'>CURRICULUM</p>
          <p className='text-center text-xs font-bold'>
            VERNACULAR <br /> MEDIUM
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default Features;
