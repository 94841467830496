export const faqlist1 = [
    {
        question: 'स्थानीय प्रशिक्षित शिक्षक',
        answer: 'ग्रासरूटस शिक्षकों की हमेशा स्थानीय स्तर पर भर्ती की जाती है। वे शिक्षाशास्त्र, विषय विशेषज्ञता और अतरिक्त कौशल के गतिविधि आधारित शिक्षण विधियों में चार दिवसीय प्रशिक्षण कार्यक्रम से गुजरते हैं।',
    },
    {
        question: 'गहन अध्ययन उपरांत रचित पाठ्यक्रम',
        answer: 'हमारी पाठ्यक्रम की रचना (डिजाइन) उन विभिन्न संरचनाओं के माध्यम से राज्य और केंद्रीय पाठ्यक्रम में जान लाती है जो एक संघर्षरत ग्रामीण शिक्षार्थी के लिए शिक्षा में आने वाले दूरी को भारती है।',
    },
    {
        question: 'ग्रामीण भारत में सबसे पहली उपस्थिति',
        answer: 'हम वहां पहुँचते हैं जहां कोई नहीं पहुँच पता । हम मानते हैं कि भारत के ग्रामीण और छोटे शहरों के शिक्षार्थियों के पोषण के लिए स्थानीय रूप से पाठ्य सामग्री का सुलभ होना महत्वपूर्ण है।',
    },
    {
        question: 'स्थानीय भाषा गढ़ित कंटेंट',
        answer: 'हमारे शिक्षकों की बहुभाषी क्षमताओं द्वारा समर्थित, एक स्थानीय (vernacular) पाठ्यक्रम कंटेंट का पावरहाउस बनाने के लिए हम प्रतिबद्ध हैं।',
    },
]; 
