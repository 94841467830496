import React from 'react'
import Features from "./Features";
import Hybrid from "./Hybrid";

import Punchline from "./Punchline";

const Landing = () => {
  return (
    <div>
      {/*  <Vernacular />
      <Assessment />
      <Slogan />
      <Pillars /> */}
      {/* I am putting these  4 elements in the hybrid section accordint to new update 4 . i have used the existing file structure for now 
      but will update the names for these files later on*/}
      <Hybrid />   
      <Punchline />
      <Features />

    </div>
  )
}

export default Landing