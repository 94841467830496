import React, {useState} from 'react';
import { HiMenuAlt3 } from 'react-icons/hi';

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav);
    };

  return (
    <div className='w-screen min-h-[50px] flex justify-end items-center absolute z-10 text-white bg-gray-700/80'>
        <ul className='hidden sm:flex sm:px-16'>
            
            <li>
                <a href='vernacular'>Pathyacharya</a>
            </li>
            <li>
                <a href='smart'>Smart Schools</a>
            </li>
            <li>
                <a href='grassroots'>Grassroots</a>
            </li>
            <li>
                <a href='assessment'>Formative Assessment</a>
            </li>
            <li>
                <a href='impact'>Highlights</a>
            </li>
        </ul>
        <div onClick={handleNav} className='sm:hidden z-10'>
            <HiMenuAlt3  className='text-white  cursor-pointer' size={20} /> 
        </div>
        <div onClick={handleNav}
            className={ 
                nav 
                ? 'overflow-y-hidden md:hidden ease-in duration-300 absolute text-gray-300 left-0 top-0 w-screen h-screen bg-black/90 px-4 py-7 flex flex-col' 
                : 'absolute top-0 h-h-screen left-[-100%] ease-in duration-500 z-10'
            }
        >   
            <ul className='w-screen h-full text-center pt-12'>

            <li className='font-bold text-2xl p-8'>
                <a href='vernacular'>Pathyacharya</a>
            </li>
            <li className='font-bold text-2xl p-8'>
                <a href='smart'>Smart Schools</a>
            </li>
            <li className='font-bold text-2xl p-8'>
                <a href='grassroots'>Grassroots</a>
            </li>
            <li className='font-bold text-2xl p-8'>
                <a href='assessment'>Formative Assessment</a>
            </li>
            <li className='font-bold text-2xl p-8'>
                <a href='impact'>Highlights</a>
            </li>
            </ul>
        </div>
    </div>        
  )
}

export default Navbar