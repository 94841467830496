import React from 'react'

const Topbar = () => {
  return (
    <div className='py-4 px-4'>
        <a href='/'><img src="https://images.schooglink.com/image/website/schooglink_logotype.png" alt="Schooglink" className='text-orange-600 font-bold text-2xl z-20 h-10' /></a>
    </div>
  )
}

export default Topbar