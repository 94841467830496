import React from 'react';
import schooglink_logo_white from '../assets/schooglink_logo_white.png';
import { 
    FaFacebook,
    FaTwitter,
    FaLinkedin,
    FaYoutube,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='w-screen flex flex-col justify-between mt-20 text-white' style={{ backgroundColor: '#3c4149', padding: '20px 40px' }}>
      {/* Main Content: Left and Right Sides */}
        <div className='w-full flex flex-col md:flex-row justify-between'>
          {/* Left Side */}
          <div className='w-full md:w-1/2  lg:pl-[142px]' >
            {/* Logo */}
            <div className='mb-2'>
              <img src={schooglink_logo_white} alt="Schooglink" className='text-orange-600 font-bold text-2xl z-20 h-10' />
            </div>
            {/* Heading */}
            <h2 className='text-md font-semibold mb-2 mt-8'>We love to hear from you!</h2>
            {/* Feedback Paragraph */}
            <p className='text-gray-400 mb-3 text-sm lg:w-[50%] sm:w-[70%]'>
              Do you have any feedback about our products or any suggestions you would like to share with us?
            </p>

            {/* Contact Information */}
            <h3 className='font-semibold mb-1 text-sm mt-8'>Contact Us:</h3>
            <p className='text-gray-400 mb-1 text-sm'>+91 80695 10555</p>
            <p className='text-gray-400 mb-3 text-sm'>contact@schooglink.com</p>

            {/* Social Media Links */}
            <h3 className='font-semibold mb-4 mt-8 text-sm'>Follow us on:</h3>
            <div className='flex space-x-8 mb-4'>
            <a href="https://www.facebook.com/schooglink/">
              <FaFacebook className='text-xl' />
            </a>
            <a href="https://twitter.com/schooglink">
              <FaTwitter className='text-xl' />
            </a>
            <a href="https://www.linkedin.com/company/schooglink">
              <FaLinkedin className='text-xl' />
            </a>
            <a href="https://www.youtube.com/channel/UCgXdXVjxtBcKP_4ta_jZj9A">
              <FaYoutube className='text-xl' />
            </a>
          </div>
      </div>

      {/* Right Side */}
      <div className='w-full md:w-1/2 mt-8 md:mt-0 lg:mt-16' >
        <h3 className='text-md font-semibold mb-2'>Our Coordinates</h3>

        {/* Bengaluru Location */}
        <h1 className='text-lg font-semibold mb-1 mt-4'>Bengaluru</h1>
        <p className='text-gray-400 mb-4 text-sm'>
          Ramasagara Village, Electronic City<br />
          Phase - 2, Bengaluru, Karnataka 560100
        </p>

        {/*  Patna Location */}
        <h1 className='text-lg font-semibold mb-1 mt-4'>Patna</h1>
        <p className='text-gray-400 text-sm'>
            New Punaichak, Rahbanshi Nagar,<br />
            Patna, Bihar
        </p>
      </div>
    </div>

    {/* Footer Bottom: Horizontal Menu */}
    <div className='w-full flex justify-start lg:justify-center items-center mt-2 pt-4'>
      <ul className='flex flex-col lg:flex-row w-full lg:w-auto gap-2 text-xs'>
        <li className='whitespace-nowrap hover:text-gray-300 mb-1 lg:mb-0 px-0 lg:px-8'>2024 - Schooglink Pvt Ltd, All Rights Reserved</li>
        <li className='mb-1 lg:mb-0 px-0 lg:px-8'><a href='aboutus' className='whitespace-nowrap hover:text-gray-300'>About Us</a></li>
        <li className='mb-1 lg:mb-0 px-0 lg:px-8'><a href='terms' className='whitespace-nowrap hover:text-gray-300'>Terms & Conditions</a></li>
        <li className='mb-1 lg:mb-0 px-0 lg:px-8'><a href='privacy' className='whitespace-nowrap hover:text-gray-300'>Privacy Policy</a></li>
        <li className='mb-1 lg:mb-0 px-0 lg:px-8'><a href='returns' className='whitespace-nowrap hover:text-gray-300'>Return Policy</a></li>
        <li className='mb-1 lg:mb-0 px-0 lg:px-8'><a href='contactus' className='whitespace-nowrap hover:text-gray-300'>Contact Us</a></li>
      </ul>
    </div>
  </div>
  );
}

export default Footer;
