import React, { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const Viewer = (props) => {
  const [totalPages, settotalPages] = useState(0);
  const [pageNumber, setpageNumber] = useState(1); // default to page number 1
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // track window width

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

    const handleResize = () => {
      setWindowWidth(window.innerWidth); // update window width on resize
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * This function will be called when the PDF is loaded
   * @param {Object} event
   */
  function onDocLoad(event) {
    settotalPages(event.numPages);
  }

  const changePage = (param) => {
    if (param === "prev" && pageNumber > 1) {
      setpageNumber((prev) => prev - 1);
    }
    if (param === "next" && pageNumber < totalPages) {
      setpageNumber((prev) => prev + 1);
    }
  };

  // Calculate width based on screen size
  const calculateWidth = () => {
    if (windowWidth <= 640) return windowWidth * 0.8; // for small screens (90% of the width)
    if (windowWidth <= 1024) return windowWidth * 0.8; // for medium screens (80% of the width)
    return 600; // default width for larger screens
  };

  // Function to handle PDF download
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = props.filename; // the file URL
    link.download = props.filename.split('/').pop(); // set the filename for download
    document.body.appendChild(link);
    link.click(); // simulate click to download
    document.body.removeChild(link); // remove link after downloading
  };

  return (
    <div className="flex flex-col items-center justify-center lg:mt-12 space-y-6">
      <div className="w-full lg:w-3/4 xl:w-2/3 flex flex-col items-center bg-gray-50 py-6 px-4 sm:px-6 md:px-8 shadow-lg rounded-lg">
        {/* PDF Viewer */}
        <div className="w-full bg-gray-100 p-4 flex justify-center items-start border border-gray-200 rounded-lg shadow-inner">
          <Document file={props.filename} onLoadSuccess={onDocLoad}>
            <Page pageNumber={pageNumber} width={calculateWidth()} /> {/* Dynamically calculate width */}
          </Document>
        </div>

        {/* PDF Navigation Controls at Bottom Center */}
        <div className="w-full flex justify-center space-x-6 mt-4">
          <IoIosArrowBack
            className={`cursor-pointer text-2xl ${pageNumber === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => changePage("prev")}
          />
          <span className="px-4 py-2 bg-gray-200 rounded-lg text-sm font-semibold">
            Page {pageNumber} of {totalPages}
          </span>
          <IoIosArrowForward
            className={`cursor-pointer text-2xl ${pageNumber === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => changePage("next")}
          />
        </div>

        {/* Download Button */}
        <div className="w-full flex justify-center mt-6">
          <button
            className="bg-blue-600 text-white px-5 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-all duration-200"
            onClick={handleDownload} // Set the onClick to handle download
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default Viewer;
