import React from 'react';
import Pillars from "./Pillars";
import Vernacular from "./Vernacular";
import Assessment from "./Assessment";
import Slogan from "./Slogan";

const Hybrid = () => {
  return (
    <div className='relative'>
  <div className='absolute inset-0 bg-gradient-to-b from-[#f7f9fc] to-[#D6E4FF] rounded-b-3xl' style={{ height: '93%', zIndex: -1 }} />
  <Vernacular />
  <Assessment />
  <Slogan />
  <Pillars />
</div>

  
  )
}

export default Hybrid