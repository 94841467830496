import React, { useState } from 'react'
import { GoTriangleDown } from "react-icons/go";
import { GoTriangleRight } from "react-icons/go";

{/* <GoTriangleDown />
    <GoTriangleRight />*/}


export default function Faq({ question, answer }) {
    const [showAnswer, setShowAnswer] = useState(false);

  return (
    <>
    <div className="bg-white">
        <article className="flex items-center justify-between px-4 lg:p-6">
          <h2
            className="cursor-pointer"
            onClick={() => setShowAnswer(!showAnswer)}
          >
            {question}
          </h2>
          <ul>
            {!showAnswer && (
              <li>
                <button onClick={() => setShowAnswer(true)}>
                  <GoTriangleDown />
                </button>
              </li>
            )}
            {showAnswer && (
              <li>
                <button onClick={() => setShowAnswer(false)}>
                  <GoTriangleRight />
                </button>
              </li>
            )}
          </ul>
        </article>

        <article
          className={`${showAnswer && "border-t border-gray-400 p-4 lg:p-6"}`}
        >
          {showAnswer && <p>{answer}</p>}
        </article>
      </div>
    
    </>
  )
}

