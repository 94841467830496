export const faqlist2 = [
  {
      question: 'सुगम लर्निंग कंटेंट',
      answer: 'भारत के सभी शिक्षा बोर्डों और प्रतियोगी परीक्षाओं के पाठ्यक्रम का विस्ता, हमारे ऑन-डिमांड ई-लर्निंग ऐप्स पाठ्यचर्या और करिकुलम, आप तक 24X7 पहुंच उपलब्ध कराती है।',
  },
  {
    question: 'कांसेप्ट की सरलता पर फोकस',
    answer: 'छात्रों को कांसेप्ट सरलता से समझाना, टेस्ट सीरीज एवं डाउट सेशन के माध्यम से सीखने की प्रगति का ट्रैक एवं परीक्षण करना हमारे छात्रों में मूलभूत विश्वास का पोषण करता है।',
  },
  {
    question: 'किफायती और अनुकूल',
    answer: 'हम सामर्थ्य को बनाए रखते हुए किफायती फीस मॉडल को जारी कर हम सिखने को प्रोत्साहन देने में आने वाली समस्यों को दूर करते हैं।',
  },
  {
    question: 'मज़बूत आधारभूत संरचना',
    answer: 'हमारी कक्षाओं में एच.डी. प्रोजेक्टर, उच्च गुणवत्ता वाले ऑडियो सिस्टम और हमारी वर्तमान संचालन टीम द्वारा समर्थित सुविधाएं हैं, जो किसी भी बाधा को दूर करने के लिए तैयार हैं जो कि शहरी-ग्रामीण (रूर्बन) उपयोगिताएं हम तक उपलब्ध करा सकती हैं।',
  },
];