import React from 'react'
import {  FaPhone } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

const ContactUs = () => {
  return (
    <div className='mt-12 flex flex-col px-4 py-8 justify-center items-center gap-12'>
      <div className=''>
      <h1 className='text-2xl px-4 py-8'> We would like to hear from you!</h1>
      <p> Do you have any feedback about our products or any 
        suggestions you would like to share with us?
      </p>
      </div>
      <div className='flex flex-wrap gap-10 justify-center items-center'>
      <div>
        <img src="https://images.schooglink.com/image/website/contact_us.png" alt='contact us' className='w-screen h-full max-h-96 max-w-96' />
      </div>
      <div className=''>
        <h1 className='text-2xl  px-4 py-8'> Contact Us </h1>
        <p className='flex flex-row' > <FaPhone></FaPhone>+91 80695 10555</p>
        <p className='flex flex-row'> <MdOutlineEmail></MdOutlineEmail>contact@schooglink.com</p>
      </div>
      </div>
      <div className='px-4 py-12'>
        <h1 className='text-2xl px-4 py-8'> Our Co-ordinates</h1>
        <div  className='flex flex-wrap gap-8' >
          <div className='max-w-96'>
            <h1 className='text-xl'>Bengaluru</h1>
            <p>Ramasagara Village, Electronic City
              Phase - 2, Bengaluru, Karnataka 560100
            </p>
            <p className='flex flex-row'><FaPhone></FaPhone>+91-6203968601</p>
          </div>
          <div className='max-w-96'>
            <h1 className='text-xl'>Patna</h1>
            <p>New Punaichak, Rahbanshi Nagar, 
              Patna, BIhar
            </p>

            <p className='flex flex-row'><FaPhone></FaPhone>+91-6203968602</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs