import React from 'react';
import SchooglinkSmartSchool from '../assets/SchooglinkSmartSchool.png';

const Assessment = () => {
  return (
    <div className='relative w-[90%] mx-auto mt-24 flex flex-col lg:flex-row'>
    {/* Image section */}
    <div className='lg:w-1/2 flex items-center justify-center'>
      <img
        src={SchooglinkSmartSchool}
        alt="Schooglink Smart School"
        className='max-w-full h-auto'
      />
    </div>
  
    {/* Text section */}
    <div className='lg:w-1/2 flex flex-col justify-center text-black p-4 text-center lg:text-left items-center lg:items-start'>
      <div className='lg:ml-auto'>
        <h1 className='text-2xl sm:text-2xl font-bold md:text-2xl lg:text-3xl'>
          Schooglink Smart School
        </h1>
        <p className='text-md sm:text-base md:text-base lg:text-xl xl:text-2xl font-semibold mt-4' style={{ color: '#161DD9' }}>
          NEP 2020 compliant smart classrooms with 360° feedback loop
        </p>
        <p className='text-sm sm:text-base md:text-lg lg:text-lg xl:text-xl mt-4 font-semibold text-gray-700'>
          A unique tech platform developed in-house that delivers, monitors, & analyzes every function of the smart hybrid classroom designed & set up by Schooglink, keeping track of the learning outcomes of all students, identifying and helping bridge their learning gaps. A platform designed for scale.
        </p>
  
        {/* Buttons */}
        <div className='flex flex-row mt-6 justify-center sm:justify-end space-x-4 w-full'>
          {/* Button 1: Get Started */}
          <a href='contactus'>
          <button
            className='bg-[#4DAF3A] text-white py-2 px-6 h-12 w-full sm:w-auto rounded-lg text-sm sm:text-base font-semibold hover:bg-green-600 transition duration-300'>
            Get Started
          </button>
          </a>
  
          {/* Button 2: Explore */}
          <a href='smart'>
          <button
            className='bg-[#203D78] text-white py-2 px-6 h-12 w-full sm:w-auto rounded-lg text-sm sm:text-base font-semibold hover:bg-blue-900 transition duration-300'>
            Explore
          </button>
          </a>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default Assessment;
