import React from 'react'

const Slogan = () => {
  return (
    <div className='flex flex-col w-full items-center mt-32  px-4'>
  {/* Main Heading */}
  <p className='font-bold text-2xl sm:text-2xl md:text-3xl lg:text-4xl text-center w-full'>
    THE SMART EDUCATION PLATFORM
  </p>

  {/* Subheading */}
  <p className='text-sm sm:text-base md:text-lg lg:text-lg mt-4 text-center text-gray-800 w-full'>
    <i>where</i> <b className='text-gray-800'>TECHNOLOGY</b> <i>drives</i> <b className='text-gray-800'>EDUCATION</b>
  </p>
</div>


  )
}

export default Slogan